<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <section>
      <div class="quote-slider">
        <div class="container">
          <div class="row ">
            <div class="col-6">
              <div class="description drive-less">
                <h2 >Drive less, <br/> Pay less</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="quotation-container mb-5" style="background-color: #fff;">
      <div class="row p-3">
        <div class="col-md-12">
          <span style="font-size:12px;color:#949494;font-family: 'Poppins', sans-serif;font-weight: 300;">Comprehensive Car Insurance</span> <br/>
          <h3 style="color:#254E90;font-family: 'Poppins', sans-serif;font-weight: 500;">
            <span style="color: rgb(0, 119, 190);"> Buy </span>
            <span style="color: rgb(228, 35, 18);">Now </span>
          </h3>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-12">
          <hr style="margin-bottom: 0px;margin-top: 0px;"/>
        </div>
      </div>



      <div class="row">
        <div class="col-md-12">



          <form method="post" action="#" v-on:submit.prevent="validateCheckoutForm">

            <div class="container">
              <div class="form-group mb-3">
                <label :class="{ 'text-danger': $v.checkout.customerFirstName.$error }">First Name: </label> <br/>
                <input v-model.trim="$v.checkout.customerFirstName.$model" class="form-control" type="text" placeholder="Enter first  name" />
                <div class="text-danger" v-if="!$v.checkout.customerFirstName.required && $v.checkout.customerFirstName.$dirty">First name is required</div>
                <div class="text-danger" v-if="!$v.checkout.customerFirstName.alpha && $v.checkout.customerFirstName.$dirty">First name accepts alphabet only</div>
                <div class="text-danger" v-if="!$v.checkout.customerFirstName.minLength && $v.checkout.customerFirstName.$dirty">First name should have a minimum of 3 characters</div>
              </div>

              <div class="form-group mb-3">
                <label >Middle Name: </label> <br/>
                <input v-model="checkout.customerMiddleName" class="form-control" type="text" placeholder="Enter middle name" />

              </div>

              <div class="form-group mb-3">
                <label :class="{ 'text-danger': $v.checkout.customerLastName.$error }">Last Name: </label> <br/>
                <input v-model.trim="$v.checkout.customerLastName.$model" class="form-control" type="text" placeholder="Enter last name" />
                <div class="text-danger" v-if="!$v.checkout.customerLastName.required && $v.checkout.customerLastName.$dirty">Last name is required</div>
                <div class="text-danger" v-if="!$v.checkout.customerLastName.alpha && $v.checkout.customerLastName.$dirty">Last name accepts alphabet only</div>
                <div class="text-danger" v-if="!$v.checkout.customerLastName.minLength && $v.checkout.customerLastName.$dirty">Last name should have a minimum of 3 characters</div>
              </div>

              <div class="form-group mb-3">
                <label :class="{ 'text-danger': $v.checkout.customerPhoneNumber.$error }">Mobile Phone </label> <br/>
                <input class="form-control" type="text" placeholder="07XXXXXXXX or 01XXXXXXXX" v-model.trim="$v.checkout.customerPhoneNumber.$model" />
                <div class="text-danger" v-if="!$v.checkout.customerPhoneNumber.numeric && $v.checkout.customerPhoneNumber.$dirty">Phone number should be numeric</div>
                <div class="text-danger" v-if="!$v.checkout.customerPhoneNumber.minLength && $v.checkout.customerPhoneNumber.$dirty">Phone number should  have 10 characters</div>
                <div class="text-danger" v-if="!$v.checkout.customerPhoneNumber.maxLength && $v.checkout.customerPhoneNumber.$dirty">Phone number should  have 10 characters</div>
              </div>
              <div class="form-group mb-3">
                <label :class="{ 'text-danger': $v.checkout.customerEmail.$error }">Email Address </label> <br/>
                <input v-model.trim="$v.checkout.customerEmail.$model"  class="form-control" type="text"  />
                <div class="text-danger" v-if="!$v.checkout.customerEmail.required && $v.checkout.customerEmail.$dirty">Email address is required</div>
                <div class="text-danger" v-if="!$v.checkout.customerEmail.email && $v.checkout.customerEmail.$dirty">Not a valid email address</div>
              </div>

              <div class="mb-2 mt-2">
                <h5>Payment Details</h5>
              </div>

              <div class="row mt-2" >
                <div class="col-12">
                  Mpesa
                </div>
              </div>

              <div class="row" >
                <div class="col-12">
                  <hr/>
                </div>
              </div>

              <div >
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label :class="{ 'text-danger': $v.mpesaPhoneNumber.$error }" ><strong>M-pesa phone number:</strong></label> <br/>
                      <input class="form-control" v-model.trim="$v.mpesaPhoneNumber.$model"  type="text" placeholder="07XXXXXXXX or 01XXXXXXXX" />
                      <div class="text-danger" v-if="!$v.mpesaPhoneNumber.required && $v.mpesaPhoneNumber.$dirty">Phone number is required</div>
                      <div class="text-danger" v-if="!$v.mpesaPhoneNumber.numeric && $v.mpesaPhoneNumber.$dirty">Phone number should be numeric</div>
                      <div class="text-danger" v-if="!$v.mpesaPhoneNumber.minLength && $v.mpesaPhoneNumber.$dirty">Phone number minimum length should be 10</div>
                      <div class="text-danger" v-if="!$v.mpesaPhoneNumber.maxLength && $v.mpesaPhoneNumber.$dirty">Phone number maximum length should be 10</div>
                    </div>
                  </div>
                </div>

                <div  class="alert alert-warning">
                  <small id="emailHelp" class="form-text text-muted "> Please ensure you have your phone near you. You will receive a prompt on the number above. Enter your M-pesa PIN to authorise payment. </small>
                </div>
              </div>

<!--              <div v-else-if="paymentMethod == 'card'">
                <div class="row">
                  <div class="col-md-12">
                    <label><strong>Card Number:</strong></label> <br/>
                    <input class="form-control" type="text" v-mask="'9999 9999 9999 9999'" v-model="card.number" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-8">
                    <label><strong>Expiration Date:</strong></label> <br/>
                    <input class="form-control" type="text" v-mask="'99/99'" v-model="card.cardExpiry" placeholder="mm/yy" />
                  </div>
                  <div class="col-md-4">
                    <label><strong>CVV:</strong></label> <br/>
                    <input class="form-control" type="text" v-mask="'999'" v-model="card.cvv" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label><strong>Card Holder Name:</strong></label> <br/>
                    <input class="form-control" type="text" v-model="card.nameOnCard" />
                  </div>
                </div>
              </div>-->

              <div class="form-group" >
                <label><strong>Referral Code (Optional ):</strong></label> <br/>
                <input class="form-control" v-model="checkout.referredBy.code"  type="text"  />
              </div>

              <div class="mb-2 mt-2">
                <h5>Car Details</h5>
              </div>

              <div class="car-details">
                <div class="row ">
                  <div class="col-12">
                    <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Value of the car : &nbsp;&nbsp;
                        <span >KES. {{ quotation.sumInsured | currency('', 0) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Product:
                        <span >Motomatic</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Make:
                        <span >{{ quotation.make }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        YoM:
                        <span >{{ quotation.yearOfManufacture }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mt-2 alert alert-info text-danger">
                Please be advised that your Motomatic vehicle Insurance Premium is contingent upon the insured value as specified in the valuation report. In the event that the valuation report indicates a higher value than originally quoted, you may be required to supplement your payment to ensure proper coverage.
              </div>

              <div class="mt-5 mb-5">
                <button style="background-color:#E42B1A;color:#fff;" type="submit"  class=" btn  btn-lg  quote-btn quotation-button">  <strong>Pay KES. </strong> {{  quotation.distancePremium | currency('', 0) }} </button>
              </div>
            </div>

          </form>
        </div>
      </div>

    </div>


    <modal name="success-checkout" height="600" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable">
      <div class="p-5 text-center success-checkout">
        <img src="@/client/assets/images/success_check.png">


        <h4 class="text-success">Payment successful</h4>

        <div>
          <button type="button" class="btn  btn-secondary btn-block quote-btn" > Proceed To dashboard </button>
        </div>

      </div>

    </modal>

    <modal height="320" name="stk-push-wait"  :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable">
      <div class="p-5 text-center ">

        <p>
          Kindly check your phone for an STK push <br/>
          to <strong>enter your Mpesa pin</strong> to complete <br/>
          the payment.
        </p>

        <div class="mt-3">
          <img src="@/client/assets/images/progress.png">
        </div>

        <h4>
          Retry in
        </h4>

        <div class="row mt-2 mb-2">
          <span style="color:#E42B1A;font-size: 1.3em;"> {{ stkPushCounter }} Seconds</span>
        </div>

      </div>
    </modal>

    <modal name="ds-modal" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable">

      <div style="background-color:#16263D;" class="p-2">
        <h5 class="text-white">Card OTP</h5>
      </div>

      <div class="p-5 text-center success-checkout">


        <iframe :src="redirectUrl" style="width: 100%; height: 500px;" />

      </div>

    </modal>

  </div>
</template>

<script>

import {alpha, email, maxLength, minLength, numeric, required} from 'vuelidate/lib/validators'
import { mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'home',
  data () {
    return {
      mpesaPhoneNumber:"",
      stkPushCounter:45,
      stkCounterCheckIntervalId:0,
      resizable: true,
      adaptive: true,
      draggable: false,
      isLoading: false,
      fullPage: true,
      quotation:{},
      checkout:{
        requestAmount: "",
        currencyCode: "KES",
        accountNumber: "speedflakes@gmail.com",
        serviceCode: "AICARE",
        dueDate: new Date().toJSON().slice(0,10).replace(/-/g,'/'),
        requestDescription: "tripbuddy",
        MSISDN: "",
        customerFirstName: "",
        customerEmail: "",
        paymentOrigin: "switch",
        packageName:"SWITCH-BRITAM",
        quoteId:"",
        customerMiddleName:"",
        customerLastName:"",
        customerPhoneNumber:"",
        referredBy: {
          code: ""
        }
      },
      card:{
        nameOnCard:"",
        number:"",
        cvv:"",
        cardExpiry:"",
        expiry:{
          month:"",
          year:""
        }
      },
      redirectUrl:"",
      paymentMethod:"Mpesa",
      transactionId:"",
      intervalId:""
    };
  },
  mounted(){
    this.quotation = JSON.parse(localStorage.getItem("quote"));

    this.checkout.quoteId = this.quotation.quoteId;
    this.checkout.requestAmount = this.quotation.distancePremium = localStorage.getItem("amountToPay");

  },

  components: { Loading },

  validations(){
    if(this.paymentMethod == 'Mpesa'){
      return {
        checkout:{
          customerFirstName:{
            required,
            alpha,
            minLength:minLength(3)
          },
          customerEmail:{
            required,
            email
          },
          customerLastName:{
            required,
            alpha,
            minLength:minLength(3)
          },
          customerPhoneNumber:{
            numeric,
            minLength: minLength(10),
            maxLength: maxLength(10)
          }
        },
        mpesaPhoneNumber:{
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10)
        }
      }
    }
    else{
      return {
        checkout:{
          customerFirstName:{required},
          customerEmail:{required},
          customerLastName:{required}
        },
      }
    }
  },

  methods:{
    ...mapActions(["makeMobileMoneyPayment","updatePersonalQuoteInformation","makeCardPayment","getTransactionsByMerchantId"]),
    updatePaymentMethod(paymentMethod){
      this.paymentMethod = paymentMethod;
    },
    startSTKPushTimer(){
      let self = this;
      this.stkCounterCheckIntervalId = setInterval(function() {
        if(self.stkPushCounter > 0){
          self.stkPushCounter--;
        } else {
          clearInterval(self.stkCounterCheckIntervalId);
          self.$modal.hide('stk-push-wait');

        }
      }, 1000);
    },
    makeMpesaPayment(){

      let self = this;

      this.isLoading = true;
      this.stkPushCounter = 45;

      let phoneNumber = this.mpesaPhoneNumber;
      this.checkout.MSISDN = phoneNumber.replace("0","+254");

      this.makeMobileMoneyPayment(this.checkout).then((response) => {
        self.isLoading = false;
        self.$modal.show('stk-push-wait');

        //console.log(response);

        self.transactionId = response.merchantTransactionID;

        self.startTransactionCheck();
        self.startSTKPushTimer();

      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })
    },

    submitCardPayment(){

      let expiry = this.card.cardExpiry.split("/");

      this.card.expiry.month = expiry[0];
      this.card.expiry.year = expiry[1];
      this.card.number = this.card.number.replaceAll(' ', '');

      //delete this.card.cardExpiry;

      let cardCheckoutData = {
        customerFirstName: this.checkout.customerFirstName,
        customerLastName: this.checkout.customerLastName,
        currencyCode: "KES",
        countryCode: "KE",
        customerEmail: this.checkout.customerEmail,
        requestDescription: "Switch product payment",
        order: {
          accountNumber: this.checkout.customerEmail,
          chargeAmount: this.checkout.requestAmount+"",
          currencyCode: "KES",
          requestDescription: "Order XX Purchase"
        },
        card: this.card,
        billingDetails: {
          address: {
            city: "Nairobi",
            countryCode: "KE"
          },
          customer: {
            firstName: this.checkout.customerFirstName,
            emailAddress: this.checkout.customerEmail,
            surname:  this.checkout.customerLastName,
            mobileNumber: this.checkout.customerPhoneNumber
          }
        },
        paymentOrigin: "switch",
        packageName: "switch-per-km",
        quoteId: this.checkout.quoteId
      };



      let self = this;

      this.isLoading = true;

      this.makeCardPayment(cardCheckoutData).then((paymentResults) => {
        self.isLoading = false;

        if(paymentResults.message == "Success"){

          self.$toast.open({
            message: "Payment successful!",
            type: 'success',
            duration:5000,
            position:"top-right"
          });

          self.$router.push("/personal-details/"+self.checkout.quoteId);

        }
        else{
          self.$toast.open({
            message: paymentResults.message,
            type: 'warning',
            duration:5000,
            position:"top-right"
          });
        }


      }).catch(error => {
        self.isLoading = false;

        self.$toast.open({
          message: error.message,
          type: 'danger',
          duration:5000,
          position:"top-right"
        });

        console.log(error);
      })


    },

    startTransactionCheck(){
      let self = this;


      this.intervalId =  setInterval(function (){self.getTransactionsByMerchantId({transactionId: self.transactionId}).then((response) => {

        clearInterval(self.intervalId);


        self.isLoading = false;

        self.$toast.open({
          message: "Payment Successful!",
          type: 'success',
          duration:5000,
          position:"top-right"
        });


        self.$router.push("/complete-profile/"+self.checkout.quoteId);

        //personal-details
        //self.$route.push({ name: 'personal-details', params: { quoteId: self.quoteId } })


      }).catch(error => {

        //console.log(error);
        console.log("No payment received");
      })}, 5000);
    },

    updateUserInformation(){

      let self = this;
      this.isLoading = true;

      let quoteData = {
        fullName:this.checkout.customerFirstName +" "+this.checkout.customerLastName,
        phoneNumber:this.checkout.MSISDN,
        emailAddress:this.checkout.customerEmail,
        config:"switch",
        referredBy: {
          code: this.checkout.referredBy.code
        }
      };


      this.updatePersonalQuoteInformation({quoteId: this.checkout.quoteId, quoteData: quoteData}).then((response) => {
        self.isLoading = false;

        if(self.paymentMethod == 'Mpesa'){
          self.makeMpesaPayment();
        }
        else{
          self.submitCardPayment();
        }

      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })
    },

    validateCheckoutForm(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateUserInformation()
      }
    },
    goBack(){
      this.$router.push('/quote');
    },

  }
}
</script>

<style>

table td{
  padding-top: 10px;
  padding-bottom: 10px;
}

.personal-info{
  min-height: 80vh;
  background-color: #F2F2F2;
}

.project-tab {
  padding: 10%;
  margin-top: -8%;
}
#tabs{
  background: #007b5e;
  color: #eee;
}
#tabs h6.section-title{
  color: #eee;
}
#tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #000;
  background-color: transparent;
  border-color: transparent transparent #666666;
  border-bottom: 3px solid !important;
  font-size: 16px;
  font-weight: bold;
}
.nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  color: #666666;
  font-size: 16px;
  font-weight: 600;
}

.nav-link:hover {
  border: none;
}

.personal-info button{
  width: 100%;
}

.success-checkout img{
  width: 80px;
  height: 60px;
}

.success-checkout h4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.success-checkout button{
  width: 80%;
}
</style>
